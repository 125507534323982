import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import FadeIn from "react-fade-in"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/index/hero"
import About from "../components/index/about"
import ContactBar from "../components/index/contactBar"
import Map from "../components/index/map"
import Partners from "../components/index/partners"

const IndexPage = ({ intl }) => (
  <Layout page="index">
    <SEO
      lang={intl.locale}
      title={intl.formatMessage({ id: "header.navlink.home" })}
    />
    <FadeIn>
      <Hero />
      <About />
      <ContactBar />
      <Map />
      <Partners />
    </FadeIn>
  </Layout>
)

export default injectIntl(IndexPage)
