import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const About = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      gallery1: file(relativePath: { eq: "gallery-1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gallery2: file(relativePath: { eq: "gallery-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Container id="taobao" className="about-1" fluid>
        <Row>
          <Col xl="6" className="my-auto gallery">
            <Img
              fluid={data.gallery1.childImageSharp.fluid}
              alt="Event Preview"
              className="mr-xl-3"
            />
          </Col>
          <Col xl="6" className="my-auto">
            <div className="ml-xl-3">
              <h1>
                <FormattedMessage
                  id="index.about.title1"
                  defaultMessage="Title"
                />
              </h1>
              <p>
                <FormattedMessage
                  id="index.about.message1"
                  defaultMessage="Message"
                />
              </p>
              <Link to="/about">
                <h3>
                  <FormattedMessage
                    id="index.about.learn"
                    defaultMessage="Learn more"
                  />
                  &#8594;
                </h3>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container id="president" className="about-2" fluid>
        <Row>
          <Col xl="6" className="my-auto gallery">
            <Img
              fluid={data.gallery2.childImageSharp.fluid}
              alt="Event Preview"
              className="ml-xl-3"
            />
          </Col>
          <Col xl="6" className="my-auto order-xl-first order-sm-last">
            <div className="mr-xl-3">
              <h1>
                <FormattedMessage
                  id="index.about.title2"
                  defaultMessage="Title"
                />
              </h1>
              <p>
                <FormattedMessage
                  id="index.about.message2"
                  defaultMessage="Message"
                />
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default injectIntl(About)
