import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import brand1 from "../../../static/images/partners/mcm.svg"
import brand2 from "../../../static/images/partners/gentlemonster.svg"
import brand3 from "../../../static/images/partners/fila.svg"
import brand4 from "../../../static/images/partners/nb.svg"
import brand5 from "../../../static/images/partners/vans.svg"
import busan from "../../../static/images/partners/busan.jpg"
import sfa from "../../../static/images/partners/sfa.png"
import amore from "../../../static/images/partners/amore.png"
import icnkr from "../../../static/images/partners/icnkr.png"
import lotte from "../../../static/images/partners/lotte.jpg"
import ssgdf from "../../../static/images/partners/ssgdf.png"
import hyundai from "../../../static/images/partners/hyundai.png"



const Partners = ({ intl }) => (
  <Container className="partners text-center" fluid>
    <h1>
      <FormattedMessage id="index.partners" defaultMessage="Partners" />
    </h1>
    <Row className="justify-content-center">
      <Col xs="12" lg="auto" className="d-none d-lg-block">
        <img src={busan} height="30" alt="partner-logo" />
      </Col>
      <Col xs="12" lg="auto">
        <img src={sfa} height="30" alt="partner-logo" />
      </Col>
      <Col xs="12" lg="auto">
        <img src={amore} height="30" alt="partner-logo" />
      </Col>
      <Col xs="12" lg="auto" className="d-none d-lg-block">
        <img src={icnkr} height="30" alt="partner-logo" />
      </Col>
      <Col xs="12" lg="auto">
        <img src={lotte} height="30" alt="partner-logo" />
      </Col>
      <Col xs="12" lg="auto">
        <img src={ssgdf} height="30" alt="partner-logo" />
      </Col>
      <Col xs="12" lg="auto">
        <img src={hyundai} height="30" alt="partner-logo" />
      </Col>
    </Row>
  </Container>
)

export default injectIntl(Partners)
