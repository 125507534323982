import React from "react"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

const ContactBar = ({ intl }) => (
  <Container className="contact-bar" fluid>
    <Row>
      <Col xl="9" className="my-auto">
        <h1>
          <FormattedMessage
            id={`index.contactbar.title`}
            defaultMessage="Title"
          />
        </h1>
        <p>
          <FormattedMessage
            id={`index.contactbar.message`}
            defaultMessage="Message"
          />
        </p>
      </Col>
      <Col xl="3" className="my-auto">
        <Link to="/contact">
          <Button className="btn-outline-filled">
            <FormattedMessage
              id={`index.contactbar.contact`}
              defaultMessage="Contact"
            />{" "}
            &#8594;
          </Button>
        </Link>
      </Col>
    </Row>
  </Container>
)

export default injectIntl(ContactBar)
