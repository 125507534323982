import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"

const Map = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "map/map.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kakao: file(relativePath: { eq: "map/kakao.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container id="map" className="map text-center" fluid>
      <h1>
        <FormattedMessage id="index.location" defaultMessage="Location" />
      </h1>
      <div className="map-wrapper ml-auto mr-auto">
        <div className="map-image">
          <a
            href="https://map.kakao.com/?urlX=504721.0&amp;urlY=1129712.0&amp;name=%EC%84%9C%EC%9A%B8%20%EC%A4%91%EA%B5%AC%20%ED%99%A9%ED%95%99%EB%8F%99%20668&amp;map_type=TYPE_MAP&amp;from=roughmap"
            target="_blank"
            rel="noreferrer"
          >
            <Img fluid={data.map.childImageSharp.fluid} alt="map" />
          </a>
        </div>
        <div className="map-credit">
          <a
            href="https://map.kakao.com"
            target="_blank"
            rel="noreferrer"
            style={{ float: "left" }}
          >
            <Img
              fluid={data.kakao.childImageSharp.fluid}
              width="72"
              height="16"
              alt="카카오맵"
              style={{ display: "block", width: "72px", height: "16px" }}
            />
          </a>
          <div
            style={{
              float: "right",
              position: "relative",
              top: "1px",
              fontSize: "11px",
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://map.kakao.com/?from=roughmap&amp;eName=%EC%84%9C%EC%9A%B8%20%EC%A4%91%EA%B5%AC%20%ED%99%A9%ED%95%99%EB%8F%99%20668&amp;eX=504721.0&amp;eY=1129712.0"
              style={{
                float: "left",
                height: "15px",
                paddingTop: "1px",
                lineHeight: "15px",
                color: "#000",
                textDecoration: "none",
              }}
            >
              길찾기
            </a>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default injectIntl(Map)
