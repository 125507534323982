import React from "react"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import ReactPlayer from "react-player"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import demoVideo from "../../../static/images/promo-video-1.mp4"
import thumbnail from "../../../static/images/thumbnail.png"
import topBorder from "../../../static/images/top-border.svg"
import bottomBorder from "../../../static/images/bottom-border.svg"
import nopath from "../../../static/images/no-path.svg"

const Hero = ({ intl }) => (
  <Container id="vision" className="hero" fluid>
    <Row className="hero-row">
      <Col xl="6">
        <h1>
          <FormattedMessage id="index.hero.title" defaultMessage="Title" />
        </h1>
        <p>
          <FormattedMessage id="index.hero.message" defaultMessage="Message" />
        </p>
        <Row className="hero-row">
          <Col xs="12" sm="auto">
            <Link to="/contact">
              <Button className="btn-filled">
                <FormattedMessage
                  id="index.hero.contact"
                  defaultMessage="Contact Us"
                />
              </Button>
            </Link>
          </Col>
          <Col xs="12" sm="auto">
            <Link to="/about">
              <Button className="btn-outline">
                <FormattedMessage
                  id="index.hero.learn"
                  defaultMessage="Learn More"
                />
                &#8594;
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
      <Col xl="6" className="d-none d-xl-block">
        <div className="react-player-demo">
          <img
            src={topBorder}
            className="react-player-border-left"
            alt="border-left"
          />
          <ReactPlayer
            url={demoVideo}
            playing={false}
            controls={true}
            light={thumbnail}
            width="100%"
          />
          <img
            src={bottomBorder}
            className="react-player-border-right"
            alt="border-right"
          />
          <img
            src={nopath}
            className="react-player-border-right-more"
            alt="border-spots"
          />
        </div>
      </Col>
    </Row>
  </Container>
)

export default injectIntl(Hero)
